/* sbf login module
 *
 * This module provides the standard sbf login stuff, and also provides low level
 * methods in case you want to make use of the functionality but don't want the default UI.
 *
 * You'll also need sbflogin.css
 */
(function (w, $) {
  if (!window.sbf) {
    window.sbf = {};
  }

  const FACEBOOK_ROW_ID = 'sbf-login-facebook-row';
  const CREATE_ACCOUNT_ROW_ID = 'sbf-login-create-account-row';
  const LOGIN_FORM_ID = 'sbf-login-form';
  const CREATE_ACCOUNT_FEATURE_ID = 'sbf-login-create-account-form';
  const MESSAGE_FEATURE_ID = 'sbf-login-message-feature';
  const FORGOT_USERNAME_FEATURE_ID = 'sbf-login-forgot-username-feature';
  const FORGOT_USERNAME_SUCCESS_FEATURE_ID = 'sbf-login-forgot-username-success-feature';
  const FORGOT_PASSWORD_FEATURE_ID = 'sbf-login-forgot-password-feature';
  const FORGOT_PASSWORD_SUCCESS_FEATURE_ID = 'sbf-login-forgot-password-success-feature';
  const GET_STARTED_FEATURE_ID = 'sbf-login-get-started-feature';
  const ASSOCIATE_FEATURE_ID = 'sbf-login-associate-feature';

  const LOGIN_FORM_USERNAME_FIELD_ID = 'sbf-login-form-username';
  const LOGIN_FORM_PASSWORD_FIELD_ID = 'sbf-login-form-password';
  const ASSOCIATE_FORM_USERNAME_FIELD_ID = 'sbf-login-associate-username';
  const ASSOCIATE_FORM_PASSWORD_FIELD_ID = 'sbf-login-associate-password';
  const FORGOT_USERNAME_EMAIL_FIELD_ID = 'sbf-login-forgot-username-email-address';
  const FORGOT_PASSWORD_USERNAME_FIELD_ID = 'sbf-login-forgot-password-username';
  const CREATE_ACCOUNT_USERNAME_FIELD_ID = 'sbf-login-create-account-username';
  const CREATE_ACCOUNT_PASSWORD_FIELD_ID = 'sbf-login-create-account-password';
  const CREATE_ACCOUNT_PASSWORD2_FIELD_ID = 'sbf-login-create-account-password2';

  const LOGIN_FORM_SPINNER_ID = 'sbf-login-spinner';
  const MESSAGE_SPINNER_ID = 'sbf-login-message-spinner';
  const CREATE_ACCOUNT_SPINNER_ID = 'sbf-login-create-account-spinner';
  const ASSOCIATE_SPINNER_ID = 'sbf-login-associate-spinner';
  const FORGOT_USERNAME_SPINNER_ID = 'sbf-login-forgot-username-spinner';
  const FORGOT_PASSWORD_SPINNER_ID = 'sbf-login-forgot-password-spinner';

  const get_started_process_options = {
    get_started: 1, // shows the overlay with all the buttons
    create_account: 2 // show the create sbf account overlay
  };
  const get_started_process_type = 1; // default to option 1

  const non_sbf_login_content = $('.signin-text-container, ' + '#' + FACEBOOK_ROW_ID + ', .signin-separator, .signin-new-to-sbf-container');

  sbf.login = {

    // holds the currently displaying "feature"
    current_feature: $('#' + LOGIN_FORM_ID),
    previous_feature: null,

    // show various features
    show_login_feature: function () {
      sbf.login.close_login_errors();
      sbf.login.current_feature.fadeOut(200, function () {
        sbf.login.show_non_sbf_login_content_feature();
        $('#' + LOGIN_FORM_ID).fadeIn(200);
        $('#' + CREATE_ACCOUNT_ROW_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + LOGIN_FORM_ID);
      });
    },

    show_non_sbf_login_content_feature: function () {
      non_sbf_login_content.fadeIn(200);
    },

    show_previous_feature: function () {
      sbf.login.close_login_errors();
      if (sbf.login.previous_feature.attr('id') == LOGIN_FORM_ID) {
        sbf.login.show_login_feature();
        sbf.login.previous_feature = null;
      } else if (sbf.login.previous_feature.attr('id') == CREATE_ACCOUNT_FEATURE_ID) {
        sbf.login.previous_feature = null;
        $('#' + FACEBOOK_ROW_ID).fadeIn(200);
        sbf.login.current_feature.fadeOut(200, function () {
          $('#' + CREATE_ACCOUNT_FEATURE_ID).fadeIn(200);
          sbf.login.current_feature = $('#' + CREATE_ACCOUNT_FEATURE_ID);
        });
      } else {
        sbf.login.current_feature.fadeOut(200, function () {
          sbf.login.previous_feature.fadeIn(200);
          sbf.login.current_feature = sbf.login.previous_feature;
          sbf.login.previous_feature = null;
        });
      }
    },

    show_message_feature: function (header, text, hide_spinner) {
      sbf.login.close_login_errors();
      $('#' + MESSAGE_FEATURE_ID + '-h3').html(header);
      $('#' + MESSAGE_FEATURE_ID + '-p').html(text);
      if (!hide_spinner) {
        $('#' + MESSAGE_SPINNER_ID).show();
      } else {
        $('#' + MESSAGE_SPINNER_ID).hide();
      }

      if (sbf.login.current_feature.attr('id') != MESSAGE_FEATURE_ID) {
        $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
        sbf.login.current_feature.fadeOut(200, function () {
          $('#' + MESSAGE_FEATURE_ID).fadeIn(200);
          sbf.login.previous_feature = sbf.login.current_feature;
          sbf.login.current_feature = $('#' + MESSAGE_FEATURE_ID);
        });
      }
    },

    show_forgot_username_feature: function () {
      sbf.login.close_login_errors();
      sbf.login.hide_non_sbf_login_content_feature();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + FORGOT_USERNAME_FEATURE_ID).fadeIn(200);
        sbf.login.previous_feature = sbf.login.current_feature;
        sbf.login.current_feature = $('#' + FORGOT_USERNAME_FEATURE_ID);
      });
    },

    show_forgot_username_success_feature: function () {
      sbf.login.close_login_errors();
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + FORGOT_USERNAME_SUCCESS_FEATURE_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + FORGOT_USERNAME_SUCCESS_FEATURE_ID);
      });
    },

    show_forgot_password_feature: function () {
      sbf.login.close_login_errors();
      sbf.login.hide_non_sbf_login_content_feature();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + FORGOT_PASSWORD_FEATURE_ID).fadeIn(200);
        sbf.login.previous_feature = sbf.login.current_feature;
        sbf.login.current_feature = $('#' + FORGOT_PASSWORD_FEATURE_ID);
      });
    },

    show_forgot_password_success_feature: function () {
      sbf.login.close_login_errors();
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + FORGOT_PASSWORD_SUCCESS_FEATURE_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + FORGOT_PASSWORD_SUCCESS_FEATURE_ID);
      });
    },

    show_associate_feature: function () {
      sbf.login.close_login_errors();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + ASSOCIATE_FEATURE_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + ASSOCIATE_FEATURE_ID);
      });
    },

    show_no_facebook_account_feature: function () {
      sbf.login.close_login_errors();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      sbf.login.current_feature.fadeOut(200, function () {
        $('#sbf-login-get-started-row').hide();
        $('#sbf-login-no-facebook-account-row').show();
        $('#' + GET_STARTED_FEATURE_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + GET_STARTED_FEATURE_ID);
      });
    },

    show_get_started_feature: function (get_started_process_type) {
      sbf.login.close_login_errors();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      // decide if should we show the create account overlay or the one with all the 'get started' options
      if (get_started_process_type && get_started_process_type == get_started_process_options.create_account) {
        sbf.login.show_create_account_feature();
      } else {
        sbf.login.close_login_errors();
        sbf.login.current_feature.fadeOut(200, function () {
          $('#sbf-login-get-started-row').show();
          $('#sbf-login-no-facebook-account-row').hide();
          $('#' + GET_STARTED_FEATURE_ID).fadeIn(200);
          sbf.login.current_feature = $('#' + GET_STARTED_FEATURE_ID);
        });
      }
    },

    show_create_account_feature: function () {
      sbf.login.hide_non_sbf_login_content_feature();
      $('#' + CREATE_ACCOUNT_ROW_ID).fadeOut(200);
      sbf.login.current_feature.fadeOut(200, function () {
        $('#' + CREATE_ACCOUNT_FEATURE_ID).fadeIn(200);
        sbf.login.current_feature = $('#' + CREATE_ACCOUNT_FEATURE_ID);
      });
    },

    hide_facebook_button: function () {
      $('#' + FACEBOOK_ROW_ID).css('overflow', 'hidden').animate({
        height: '0px',
        'padding-top': '0px',
        'padding-bottom': '0px',
        'margin-top': '0px',
        'margin-bottom': '0px',
        opacity: 0
      },
      {
        complete: function () {
          $('#' + FACEBOOK_ROW_ID).css({
            display: 'none',
            height: '',
            overflow: '',
            'padding-top': '',
            'padding-bottom': '',
            'margin-top': '',
            'margin-bottom': '',
            opacity: ''
          });
        }
      });
    },

    hide_non_sbf_login_content_feature: function () {
      non_sbf_login_content.fadeOut(200);
    },

    // process actions: ajax calls
    process_login: function (username, password, callback_on_success, callback_on_fail) {
      $('#' + LOGIN_FORM_SPINNER_ID).show();
      $('#' + ASSOCIATE_SPINNER_ID).show();
      $.ajax({
        url: '/ajax/sbflogin/login',
        type: 'post',
        dataType: 'json',
        data: { username, password },
        success: function (response) {
          if (response.data && response.data.success && typeof callback_on_success === 'function') {
            callback_on_success(response);
          } else if (typeof callback_on_fail === 'function') {
            $('#' + LOGIN_FORM_SPINNER_ID).hide();
            $('#' + ASSOCIATE_SPINNER_ID).hide();
            callback_on_fail(response);
          }
        },

        error: function (jqXHR, textStatus, errorThrown) {
          $('#' + LOGIN_FORM_SPINNER_ID).hide();
          $('#' + ASSOCIATE_SPINNER_ID).hide();
          if (typeof callback_on_fail === 'function') {
            callback_on_fail(jqXHR, textStatus, errorThrown);
          }
        }
      });
    },

    process_forgot_username: function (email_address, callback_on_success, callback_on_fail) {
      $('#' + FORGOT_USERNAME_SPINNER_ID).show();
      $.ajax({
        url: '/ajax/sbflogin/forgotusername',
        type: 'post',
        dataType: 'json',
        data: { emailAddress: email_address },
        success: function (response) {
          $('#' + FORGOT_USERNAME_SPINNER_ID).hide();
          if (response.data && response.data.success && typeof callback_on_success === 'function') {
            callback_on_success(response);
          } else if (typeof callback_on_fail === 'function') {
            callback_on_fail(response);
          }
        },

        error: function (jqXHR, textStatus, errorThrown) {
          $('#' + FORGOT_USERNAME_SPINNER_ID).hide();
          if (typeof callback_on_fail === 'function') {
            callback_on_fail(jqXHR, textStatus, errorThrown);
          }
        }
      });
    },

    process_forgot_password: function (username, callback_on_success, callback_on_fail) {
      $('#' + FORGOT_PASSWORD_SPINNER_ID).show();
      $.ajax({
        url: '/ajax/sbflogin/forgotpassword',
        type: 'post',
        dataType: 'json',
        data: { username },
        success: function (response) {
          $('#' + FORGOT_PASSWORD_SPINNER_ID).hide();
          if (response.data && response.data.success && typeof callback_on_success === 'function') {
            callback_on_success(response);
          } else if (typeof callback_on_fail === 'function') {
            callback_on_fail(response);
          }
        },

        error: function (jqXHR, textStatus, errorThrown) {
          $('#' + FORGOT_PASSWORD_SPINNER_ID).hide();
          if (typeof callback_on_fail === 'function') {
            callback_on_fail(jqXHR, textStatus, errorThrown);
          }
        }
      });
    },

    process_create_account: function (username, password, password2, callback_on_success, callback_on_bad_username, callback_on_fail) {
      $('#' + CREATE_ACCOUNT_SPINNER_ID).show();
      $.ajax({
        url: '/ajax/sbflogin/requestsbfaccount',
        type: 'post',
        dataType: 'json',
        data: { username, password, password2 },
        success: function (response) {
          if (response.data && response.data.success && typeof callback_on_success === 'function') {
            callback_on_success(response);
          } else {
            if (response.error && response.error.code == 4 && typeof callback_on_bad_username === 'function') {
              $('#' + CREATE_ACCOUNT_SPINNER_ID).hide();
              callback_on_bad_username(response);
            } else if (typeof callback_on_fail === 'function') {
              $('#' + CREATE_ACCOUNT_SPINNER_ID).hide();
              callback_on_fail(response);
            }
          }
        },

        error: function (jqXHR, textStatus, errorThrown) {
          $('#' + CREATE_ACCOUNT_SPINNER_ID).hide();
          if (typeof callback_on_fail === 'function') {
            callback_on_fail(jqXHR, textStatus, errorThrown);
          }
        }
      });
    },

    // event handlers and process return handlers
    login_form_submit_handler: function (submit) {
      submit.preventDefault();
      sbf.login.close_login_errors();
      const username = $('#' + LOGIN_FORM_USERNAME_FIELD_ID).val();
      const password = $('#' + LOGIN_FORM_PASSWORD_FIELD_ID).val();

      if (!username.length) {
        sbf.login.show_login_error(LOGIN_FORM_USERNAME_FIELD_ID, 'Please enter your username.');
      } else if (!password.length) {
        sbf.login.show_login_error(LOGIN_FORM_PASSWORD_FIELD_ID, 'Please enter your password.');
      } else {
        sbf.login.close_login_errors();
        sbf.login.process_login(username, password, sbf.login.login_form_success_handler, sbf.login.login_form_error_handler);
      }
    },

    login_form_success_handler: function (response) {
      window.location = '/register/account/processlogin';
    },

    login_form_error_handler: function (jqXHR, statusText, errorThrown) {
      // jqXHR contains service response
      const error_code = jqXHR.error.code;
      if (error_code == 1103) {
        // 1103 is the error code (api error code) for bad username password (InvalidCredentials)
        sbf.login.show_login_error(LOGIN_FORM_ID, 'Your username and password combination was invalid. Please try again.');
      } else if (error_code == 1107) {
        // 1107 is the error code (api error code) for password that does not meet security standards(InsecurePassword)
        sbf.login.show_login_error(LOGIN_FORM_ID, 'Your password does not meet our security standards. A password reset link has been sent to the email address you\'ve provided.');
      } else if (error_code == 1200) {
        // 1200 is the error code (api error code) for RequestParameterRequired
        sbf.login.show_login_error(LOGIN_FORM_ID, '<strong>Whoops!</strong> Username and Password are required when logging in.');
      } else if (error_code == 1201) {
        // 1201 is the error code (api error code) for Validation
        sbf.login.show_login_error(LOGIN_FORM_ID, '<strong>Whoops!</strong> Your account has been suspended.');
      } else {
        sbf.login.show_login_error(LOGIN_FORM_ID, '<strong>Whoops!</strong> There was a problem logging you in, please try again later.');
      }
    },

    associate_form_submit_handler: function (submit) {
      submit.preventDefault();
      sbf.login.close_login_errors();
      const username = $('#' + ASSOCIATE_FORM_USERNAME_FIELD_ID).val();
      const password = $('#' + ASSOCIATE_FORM_PASSWORD_FIELD_ID).val();

      if (!username.length) {
        sbf.login.show_login_error(ASSOCIATE_FORM_USERNAME_FIELD_ID, 'Please enter your username.');
      } else if (!password.length) {
        sbf.login.show_login_error(ASSOCIATE_FORM_PASSWORD_FIELD_ID, 'Please enter your password.');
      } else {
        sbf.login.close_login_errors();
        sbf.login.process_login(username, password, sbf.login.login_associate_success_handler, sbf.login.login_associate_error_handler);
      }
    },

    login_associate_success_handler: function () {
      // Login was successful, connect them
      sbf.social_connect.process_fb_connect(sbf.login.login_form_success_handler, function () {
        sbf.login.show_login_error(ASSOCIATE_FEATURE_ID, 'St. Baldrick\'s account is already connected to another Facebook user.');
        window.location = '/register/account/processlogin';
      }, function () {
        sbf.login.show_login_error(ASSOCIATE_FEATURE_ID, 'St. Baldrick\'s account is already connected to another Facebook user.');
        window.location = '/register/account/processlogin';
      },
      false);
    },

    login_associate_error_handler: function (jqXHR, statusText, errorThrown) {
      sbf.login.show_login_error(ASSOCIATE_FEATURE_ID, '<strong>Whoops!</strong> Your username and password combination was invalid. Please try again. Remember that usernames and passwords are case sensitive, so check your caps lock.');
    },

    forgot_username_submit_handler: function (submit) {
      submit.preventDefault();
      const email_address = $('#' + FORGOT_USERNAME_EMAIL_FIELD_ID).val();

      if (!email_address.length) {
        sbf.login.show_login_error(FORGOT_USERNAME_EMAIL_FIELD_ID, 'Please enter a valid email address.');
      } else {
        sbf.login.close_login_errors();
        sbf.login.process_forgot_username(email_address, sbf.login.forgot_username_success_handler, sbf.login.forgot_username_error_handler);
      }
    },

    forgot_username_success_handler: function (response) {
      sbf.login.show_forgot_username_success_feature();
    },

    forgot_username_error_handler: function (jqXHR, statusText, errorThrown) {
      // jqXHR contains service response
      const error_code = jqXHR.error.code;

      if (error_code == 5) {
        // 5 is the error code for failed mandrill email attempt
        sbf.login.show_login_error(FORGOT_USERNAME_EMAIL_FIELD_ID, '<strong>Whoops!</strong> Something went wrong sending your username information. Please wait a few seconds and try again.');
      } else {
        sbf.login.show_forgot_username_success_feature();
      }
    },

    forgot_password_submit_handler: function (submit) {
      submit.preventDefault();
      const username = $('#' + FORGOT_PASSWORD_USERNAME_FIELD_ID).val();

      if (!username.length) {
        sbf.login.show_login_error(FORGOT_PASSWORD_USERNAME_FIELD_ID, 'Please enter a valid username.');
      } else {
        sbf.login.close_login_errors();
        sbf.login.process_forgot_password(username, sbf.login.forgot_password_success_handler, sbf.login.forgot_password_error_handler);
      }
    },

    forgot_password_success_handler: function (response) {
      sbf.login.show_forgot_password_success_feature();
    },

    forgot_password_error_handler: function (jqXHR, statusText, errorThrown) {
      // jqXHR contains service response
      const error_code = jqXHR.error.code;

      if (error_code == 6) {
        // 6 is the error code for failed mandrill email attempt
        sbf.login.show_login_error(FORGOT_PASSWORD_USERNAME_FIELD_ID, '<strong>Whoops!</strong> Something went wrong sending your password reset information. Please wait a few seconds and try again.');
      } else {
        // invalid username, but display like was successful to not aid in farming usernames
        sbf.login.show_forgot_password_success_feature();
      }
    },

    create_account_submit_handler: function (submit) {
      submit.preventDefault();
      sbf.login.close_login_errors();

      const username = $('#' + CREATE_ACCOUNT_USERNAME_FIELD_ID).val();
      const password = $('#' + CREATE_ACCOUNT_PASSWORD_FIELD_ID).val();
      const password2 = $('#' + CREATE_ACCOUNT_PASSWORD2_FIELD_ID).val()
                ;

      if (!username.length) {
        sbf.login.show_login_error(CREATE_ACCOUNT_USERNAME_FIELD_ID, 'Please fill out your new username.');
      } else if (!password.length) {
        sbf.login.show_login_error(CREATE_ACCOUNT_PASSWORD_FIELD_ID, 'Please fill out your new password.');
      } else if (!password2.length) {
        sbf.login.show_login_error(CREATE_ACCOUNT_PASSWORD2_FIELD_ID, 'Please confirm your new password.');
      } else if (password !== password2) {
        sbf.login.show_login_error(CREATE_ACCOUNT_PASSWORD_FIELD_ID, '<strong>Your passwords do not match.</strong> Please create a password, then type it again to confirm.');
      } else {
        sbf.login.process_create_account(username, password, password2, sbf.login.create_account_success_handler, sbf.login.create_account_bad_username_handler, sbf.login.create_account_error_handler);
      }
    },

    create_account_success_handler: function (response) {
      window.location = $('#sbf-login-process-type').attr('data-sbf-login-create-account-url');
    },

    create_account_bad_username_handler: function (response) {
      sbf.login.show_login_error(CREATE_ACCOUNT_USERNAME_FIELD_ID, '<strong>Sorry, that username is not available!</strong> Please choose another.');
    },

    create_account_error_handler: function (jqXHR, statusText, errorThrown) {
      const error_code = jqXHR.error.code;
      if (error_code == 1107) {
        sbf.login.show_login_error(CREATE_ACCOUNT_PASSWORD_FIELD_ID, 'Your password must be at least 8 characters with a combination of lowercase letters, uppercase letters, numbers and symbols.');
      } else {
        sbf.login.show_login_error(CREATE_ACCOUNT_USERNAME_FIELD_ID, '<strong>Whoops!</strong> Sorry, something went wrong and we couldn&rsquo;t create your account. Refresh the page and try again.');
      }
    },

    // handling errors
    show_login_error: function (id, errorhtml) {
      sbf.form_errors.show_error(id, errorhtml);
    },

    close_login_errors: function () {
      sbf.form_errors.close_all_errors('sbf-login-partial');
    },

    // init function
    init: function () {
      if (sbf.login.current_feature.attr('id') != LOGIN_FORM_ID) {
        sbf.login.current_feature = $('#' + CREATE_ACCOUNT_FEATURE_ID);
      }

      // typing in an input field should remove all errors
      $('#sbf-login-partial input').keydown(function () {
        $('#sbflogin-partial input').removeClass('error');
        sbf.login.close_login_errors();
      });

      // bind reset social alert close button
      $('#sbf-login-reset-social-close').bind('click', function (click) {
        click.preventDefault();
        $('#sbf-login-reset-social-alert').fadeOut();
      });

      // what all closes current feature and reverts to standard login
      $('#sbf-login-associate-feature-close,' +
              '#sbf-login-no-facebook-account-feature-close,' +
              '#sbf-login-create-account-close, #sbf-login-forgot-password-success-back').bind('click', function (click) {
        click.preventDefault();
        sbf.login.show_login_feature();
      });

      $('#sbf-login-forgot-username-cancel, #sbf-login-forgot-password-cancel,' +
              '#sbf-login-forgot-username-success-back, #sbf-login-message-feature-close').bind('click', function (click) {
        click.preventDefault();
        sbf.login.show_previous_feature();
      });

      // bind submit handlers
      $('#' + LOGIN_FORM_ID).bind('submit', sbf.login.login_form_submit_handler);
      $('#' + CREATE_ACCOUNT_FEATURE_ID).bind('submit', sbf.login.create_account_submit_handler);
      $('#' + ASSOCIATE_FEATURE_ID).bind('submit', sbf.login.associate_form_submit_handler);
      $('#' + FORGOT_USERNAME_FEATURE_ID).bind('submit', sbf.login.forgot_username_submit_handler);
      $('#' + FORGOT_PASSWORD_FEATURE_ID).bind('submit', sbf.login.forgot_password_submit_handler);

      // show features on link click
      $('#sbf-login-create-account-link').bind('click', function (click) {
        click.preventDefault();
        sbf.login.show_create_account_feature();
      });

      $('#sbf-login-forgot-username-link, #sbf-login-associate-forgot-username').bind('click', function (click) {
        click.preventDefault();
        sbf.login.show_forgot_username_feature();
      });

      $('#sbf-login-forgot-password-link, #sbf-login-associate-forgot-password').bind('click', function (click) {
        click.preventDefault();
        sbf.login.show_forgot_password_feature();
      });

      // get-started feature
      $('#sbf-login-get-started-button').bind('click', function (click) {
        click.preventDefault();
        // which overlay to show when the 'get started' button is clicked
        const get_started_process_option = $('#sbf-login-process-type').attr('data-sbf-login-process-type') || get_started_process_type;
        sbf.login.show_get_started_feature(get_started_process_option);
      });

      // handle reset-social overlay
      if ($('#sbf-resetsocial-overlay').length) {
        sbf.overlay.show('#sbf-resetsocial-overlay');

        $('#sbf-resetsocial-overlay #sbf-close-overlay').bind('click', function () {
          sbf.overlay.hide();
        });
      }
      $('#sbf-noprofile-overlay #sbf-close-overlay').bind('click', function () {
        sbf.overlay.hide();
      });
    }
  };

  $(sbf.login.init);
})(window, jQuery);
